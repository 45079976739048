import { ApiErrorCode } from '@leon-hub/api';
import { AppError } from '@leon-hub/app-errors';
export class RegistrationFormCorruptedError extends AppError {
    constructor(options){
        super({
            ...options,
            code: ApiErrorCode.API_UNEXPECTED_ERROR,
            message: `Registration form corrupted: ${options?.message || 'Form config is empty'}`
        });
    }
}
