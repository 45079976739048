import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CopyPassword',
    props: {
        userPassword: {}
    },
    emits: [
        "click",
        "keydown"
    ],
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                tabindex: "0",
                class: _normalizeClass(_ctx.$style['copy-password']),
                onClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('click')),
                onKeydown: _cache[1] || (_cache[1] = ($event)=>_ctx.$emit('keydown'))
            }, [
                _createVNode(_unref(VIcon), {
                    class: _normalizeClass(_ctx.$style['lock-icon']),
                    name: _unref(IconName).LOCK,
                    size: _unref(IconSize).SIZE_16
                }, null, 8, [
                    "class",
                    "name",
                    "size"
                ]),
                _createElementVNode("span", null, _toDisplayString(_ctx.userPassword), 1),
                _createVNode(_unref(VIcon), {
                    class: _normalizeClass(_ctx.$style['copy-icon']),
                    name: _unref(IconName).FILES,
                    size: _unref(IconSize).SIZE_16
                }, null, 8, [
                    "class",
                    "name",
                    "size"
                ])
            ], 34)), [
                [
                    _directive_auto_id,
                    'CopyPassword'
                ]
            ]);
        };
    }
});
