import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import useSimpleRegistrationTopBarRouteComponent from 'web/src/modules/registration/submodules/simplified-registration/composables/useSimpleRegistrationTopBarRouteComponent';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SimpleRegistrationTopBarRouteComponent',
    setup (__props) {
        const { title, showModalOnLeaveEnabled, onClose, onBack } = useSimpleRegistrationTopBarRouteComponent();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: _unref(title),
                "only-emit-events": _unref(showModalOnLeaveEnabled),
                onClose: _unref(onClose),
                onBack: _unref(onBack)
            }, null, 8, [
                "title",
                "only-emit-events",
                "onClose",
                "onBack"
            ])), [
                [
                    _directive_auto_id,
                    'SimpleRegistrationTopBarRouteComponent'
                ]
            ]);
        };
    }
});
