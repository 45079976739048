import { ref, computed, toRef } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import { useSimplifiedRegistrationStore } from 'web/src/modules/registration/submodules/simplified-registration/store';
import { RegistrationStage } from 'web/src/modules/registration/enums';
import FormControlType from 'web/src/components/Form/enums/FormControlType';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useUserStore } from 'web/src/modules/user/store';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import isChangePasswordFormData from 'web/src/modules/registration/submodules/simplified-registration/guards/isChangePasswordFormData';
import { PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
export function useChangePasswordStage(emits) {
    const simpleRegStore = useSimplifiedRegistrationStore();
    const userStore = useUserStore();
    const errorConverter = useErrorsConverter();
    const { $translate } = useI18n();
    const passwordChangeForm = ref();
    const stage = toRef(()=>simpleRegStore.stage);
    const oldPassword = toRef(()=>simpleRegStore.generatedPassword);
    const isFormPending = ref(false);
    const { showDialog } = useDialogs();
    const isSuccess = computed(()=>stage.value === RegistrationStage.ChangePasswordSuccess);
    function goToDeposits() {
        simpleRegStore.goToRouteName(RouteName.DEPOSITS);
    }
    const uiSchema = computed(()=>({
            order: [
                'newPassword',
                'oldPassword'
            ],
            fields: {
                newPassword: {
                    widget: FormControlType.PasswordValidator,
                    title: $translate('WEB2_PASSWORD_INPUT_LABEL').value,
                    options: {
                        placeholder: $translate('WEB2_PASSWORD_INPUT_PLACEHOLDER').value
                    }
                },
                oldPassword: {
                    hidden: true,
                    widget: FormControlType.Hidden,
                    default: oldPassword.value || void 0
                }
            },
            submitButton: {
                label: $translate('WEB2_REGISTRATION_CHANGE_PASSWORD').value,
                disabled: false
            }
        }));
    async function onChangePasswordSubmit(data) {
        isChangePasswordFormData(data.formData);
        try {
            isFormPending.value = true;
            await userStore.changePassword({
                oldPassword: encodeURIComponent(data.formData.oldPassword),
                newPassword: encodeURIComponent(data.formData.newPassword)
            });
            simpleRegStore.goToStage({
                stage: RegistrationStage.ChangePasswordSuccess
            });
        } catch (rawError) {
            const error = errorConverter.convertToBaseError(rawError);
            simpleRegStore.setRegistrationError(error.message);
            showDialog({
                presetName: PresetName.ALERT_WARNING,
                options: {
                    title: $translate('JS_CAPTION_ATTENTION').value,
                    confirmMessage: error.message
                }
            }).promise.then(()=>{
                passwordChangeForm.value?.reset();
            });
        } finally{
            isFormPending.value = false;
        }
    }
    function emitOnInput(payload) {
        emits('input', payload);
    }
    return {
        isSuccess,
        uiSchema,
        isFormPending,
        goToDeposits,
        onChangePasswordSubmit,
        emitOnInput,
        passwordChangeForm
    };
}
