export default ((saved)=>{
    const keys = Object.keys(saved);
    if (!keys.length) return null;
    const formData = new Map();
    const touched = new Set();
    for (const key of keys){
        formData.set(key, saved[key]);
        touched.add(key);
    }
    return {
        formData,
        touched
    };
});
