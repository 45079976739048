import { watch } from 'vue';
import { PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
export const EXCLUDED_COUNTRY_DIALOG_ID = 'excluded-country-dialog';
export function useExcludedCountryDialog(isExcludedCountry) {
    const { showDialog } = useDialogs();
    const { $translate } = useI18n();
    watch(isExcludedCountry, (value)=>{
        if (value) showDialog({
            presetName: PresetName.CMS_MODAL,
            options: {
                title: $translate('WEB2_IMPORTANT_TITLE').value,
                cmsKey: 'WEB2_REGISTRATION_CAUTION',
                fullHeight: false,
                iconName: void 0,
                buttons: [
                    {
                        label: $translate('WEB2_NEXT').value
                    }
                ]
            },
            id: EXCLUDED_COUNTRY_DIALOG_ID
        });
    }, {
        immediate: true
    });
}
