import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { RegistrationStage } from 'web/src/modules/registration/enums';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { useSimpleRegistrationRoute } from 'web/src/modules/registration/submodules/simplified-registration/composables/useSimpleRegistrationRoute';
import RegistrationMain from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/RegistrationMain.vue';
import RegistrationChangePassword from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/RegistrationChangePassword.vue';
import RegistrationPhoneCheck from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/RegistrationPhoneCheck.vue';
import RegistrationChangePhoneNumber from 'web/src/modules/registration/components/RegistrationChangePhoneNumber/RegistrationChangePhoneNumber.vue';
import RegistrationSuccess from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/RegistrationSuccess.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SimpleRegistrationRouteComponent',
    props: {
        stage: {}
    },
    setup (__props) {
        /* eslint-disable no-param-reassign */ const props = __props;
        const { isFormLoaded, registrationStage, onInput, onSubmitWrapped, onChangePhone, onCheckSmsCode } = useSimpleRegistrationRoute(props);
        const stageComponent = computed(()=>{
            switch(registrationStage.value){
                case RegistrationStage.ChangePhone:
                    return RegistrationChangePhoneNumber;
                case RegistrationStage.PhoneCheckByCall:
                case RegistrationStage.PhoneCheck:
                    return RegistrationPhoneCheck;
                case RegistrationStage.ChangePasswordSuccess:
                case RegistrationStage.ChangePassword:
                    return RegistrationChangePassword;
                case RegistrationStage.Success:
                    return RegistrationSuccess;
                case RegistrationStage.Main:
                    return RegistrationMain;
                default:
                    return RegistrationMain;
            }
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _unref(isFormLoaded) ? (_openBlock(), _createBlock(_resolveDynamicComponent(stageComponent.value), {
                    key: 1,
                    onInput: _unref(onInput),
                    onSubmit: _unref(onSubmitWrapped),
                    onChangePhone: _unref(onChangePhone),
                    onCheckSmsCode: _unref(onCheckSmsCode)
                }, null, 40, [
                    "onInput",
                    "onSubmit",
                    "onChangePhone",
                    "onCheckSmsCode"
                ])) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                }))
            ])), [
                [
                    _directive_auto_id,
                    'SimpleRegistrationRouteComponent'
                ]
            ]);
        };
    }
});
