import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { watchEffect } from 'vue';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useRegistrationGeneratedPassword } from 'web/src/modules/registration/store/composables/useRegistrationGeneratedPassword';
import fetchRegistrationForms from 'web/src/modules/registration/utils/fetchRegistrationForms';
import { useRegistrationComplete } from 'web/src/modules/registration/store/composables/useRegistrationComplete';
let RegistrationPrefetch = class RegistrationPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    computeCacheKey() {
        return this.cacheIteration;
    }
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        fetchRegistrationForms();
        next();
    }
    constructor(){
        super();
        _define_property(this, "cacheIteration", 0);
        watchEffect(()=>{
            const isRegistrationComplete = useRegistrationComplete().value;
            const hasPasswordGenerationRoutine = !!useRegistrationGeneratedPassword().value;
            if (!(isRegistrationComplete && hasPasswordGenerationRoutine)) this.cacheIteration += 1;
        });
    }
};
export { RegistrationPrefetch as default };
