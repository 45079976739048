import { ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { logger } from '@leon-hub/logging';
import RouteName from '@leon-hub/routing-config-names';
import { useSimplifiedRegistrationStore } from 'web/src/modules/registration/submodules/simplified-registration/store';
import copyTextToClipboard from 'web/src/utils/browser/copyTextToClipboard';
import { registrationStageRouteMap } from 'web/src/modules/registration/utils/utils';
import { RegistrationStage } from 'web/src/modules/registration/enums';
export function useSuccessStage() {
    const simpleRegStore = useSimplifiedRegistrationStore();
    const router = useRouter();
    const userPassword = toRef(simpleRegStore, 'generatedPassword');
    const isTooltipShown = ref(false);
    async function onCopyPasswordClick() {
        try {
            if (userPassword.value) await copyTextToClipboard(userPassword.value);
            if (!isTooltipShown.value) {
                isTooltipShown.value = true;
                setTimeout(()=>{
                    isTooltipShown.value = false;
                }, 1500);
            }
        } catch (error) {
            logger.warn('Failed to copy password to clipboard', error);
        }
    }
    function goToChangePassword() {
        simpleRegStore.goToStage({
            stage: RegistrationStage.ChangePassword
        });
        router.push({
            name: registrationStageRouteMap[RegistrationStage.ChangePassword]
        });
    }
    function goToDeposit() {
        simpleRegStore.goToRouteName(RouteName.DEPOSITS);
    }
    return {
        userPassword,
        isTooltipShown,
        onCopyPasswordClick,
        goToChangePassword,
        goToDeposit
    };
}
