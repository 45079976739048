import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import { useDesktopModalStore } from 'web/src/modules/core/store';
export function useDesktopModalCloseEvent(callback) {
    const { addCloseEventListener, removeCloseEventListener } = useDesktopModalStore();
    onComponentActivated(()=>{
        addCloseEventListener(callback);
    });
    onComponentDeactivated(()=>{
        removeCloseEventListener(callback);
    });
}
