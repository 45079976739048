import { toRef } from 'vue';
import { AlertIconName, IconName } from '@leon-hub/icons';
import { ModalWidth } from '@leon-hub/module-modal';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { getImageOrIcon } from 'web/src/modules/icons';
import { useRegistrationProgress } from 'web/src/modules/registration/submodules/composables/useRegistrationProgress';
export function useRegistrationShowLeaveModal() {
    const siteConfigStore = useSiteConfigStore();
    const registrationProgress = useRegistrationProgress();
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const showModalOnLeaveEnabled = toRef(siteConfigStore, 'showModalOnLeaveEnabled');
    const isNotFinished = toRef(registrationProgress, 'isNotFinished');
    function showLeaveModal(confirmCallback, closeCallback) {
        if (!showModalOnLeaveEnabled.value) return Promise.resolve();
        if (!isNotFinished.value) return Promise.resolve();
        const { iconName, image } = getImageOrIcon({
            icon: IconName.FAST_GAMES,
            alertIcon: AlertIconName.Present2
        });
        const { promise, subscribe } = showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                confirmMessage: $translate('WEB2_REG_ON_LEAVE_MSG').value,
                title: $translate('WEB2_REG_ON_LEAVE_TITLE').value,
                iconName,
                image,
                isProfileLayout: false,
                buttons: [
                    {
                        action: DialogAction.CONFIRM,
                        label: $translate('WEB2_REG_ON_LEAVE_BACK_BUTTON').value
                    },
                    {
                        action: DialogAction.SECONDARY_CLICK,
                        label: $translate('WEB2_REG_ON_LEAVE_CLOSE_BUTTON').value
                    }
                ],
                width: ModalWidth.MEDIUM
            }
        });
        subscribe({
            [DialogAction.CONFIRM]: ()=>{
                confirmCallback?.();
            },
            [DialogAction.SECONDARY_CLICK]: ()=>{
                closeCallback?.();
            },
            [DialogAction.MODAL_CLOSE]: ()=>{
                confirmCallback?.();
            }
        });
        return promise;
    }
    return {
        showLeaveModal
    };
}
