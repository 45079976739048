import { _ as _define_property } from "@swc/helpers/_/_define_property";
let RegistrationRouterParametersHolder = class RegistrationRouterParametersHolder {
    static setInputEmail(method) {
        RegistrationRouterParametersHolder.inputEmail = method;
    }
    static getInputEmail() {
        return RegistrationRouterParametersHolder.inputEmail;
    }
};
_define_property(RegistrationRouterParametersHolder, "inputEmail", void 0);
export { RegistrationRouterParametersHolder as default };
