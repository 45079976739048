import { toRef, ref, computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
export default function useTermsAcceptionCheckbox(props) {
    const isExpandable = toRef(props, 'expandable');
    const isExpanded = ref(!isExpandable.value || !!props.open);
    const toggleExpand = ()=>{
        if (!isExpandable.value) return;
        isExpanded.value = !isExpanded.value;
    };
    const chevronProperties = computed(()=>({
            name: isExpanded.value ? IconName.EXPAND_UP : IconName.EXPAND_DOWN,
            size: IconSize.SIZE_24
        }));
    const checkboxProps = computed(()=>({
            ...props,
            id: props.name
        }));
    return {
        isExpanded,
        toggleExpand,
        checkboxProps,
        chevronProperties
    };
}
