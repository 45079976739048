import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import RouteName from '@leon-hub/routing-config-names';
import { VLoader } from '@components/loader';
import { VForm } from 'web/src/components/Form';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VReCaptchaBadge from 'web/src/modules/captcha/components/VReCaptchaBadge/VReCaptchaBadge.vue';
import registrationFormWidgets from 'web/src/modules/registration/components/RegistrationFormWidgets/commonRegistrationWidgets';
import { useMainStage } from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/composables/useMainStage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RegistrationMain',
    emits: [
        "submit",
        "change",
        "input"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const { formRef, isTermsCheckboxHidden, freeBetBannerUrl, bannerUrl, tabsItems, customErrors, isFormPending, schema, uiSchema, onSubmit, emitInput, tabsProperties, onTabClick, restoreFromSnapshot, isLargerPaddingBottom } = useMainStage(emits);
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _unref(freeBetBannerUrl) || _unref(bannerUrl) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['registration__freebet-banner'])
                }, [
                    _createVNode(VImage, {
                        src: _unref(freeBetBannerUrl) || _unref(bannerUrl),
                        class: _normalizeClass(_ctx.$style['registration__freebet-banner-img'])
                    }, null, 8, [
                        "src",
                        "class"
                    ])
                ], 2)) : _createCommentVNode("", true),
                _unref(tabsItems).length > 1 ? (_openBlock(), _createBlock(VTabs, _mergeProps({
                    key: 1,
                    class: _ctx.$style['registration__tabs']
                }, _unref(tabsProperties), {
                    "with-gap": "",
                    onTabClick: _unref(onTabClick)
                }), null, 16, [
                    "class",
                    "onTabClick"
                ])) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['registration__content']]: true,
                        [_ctx.$style['registration__content--larger-padding-bottom']]: _unref(isLargerPaddingBottom)
                    })
                }, [
                    _unref(schema) && _unref(uiSchema) ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _createVNode(_unref(VForm), {
                            ref_key: "formRef",
                            ref: formRef,
                            schema: _unref(schema),
                            "ui-schema": _unref(uiSchema),
                            "custom-errors": _unref(customErrors),
                            "is-pending": _unref(isFormPending),
                            class: _normalizeClass(_ctx.$style['registration__form']),
                            onSubmit: _unref(onSubmit),
                            onInput: _unref(emitInput),
                            onVnodeMounted: _unref(restoreFromSnapshot)
                        }, _createSlots({
                            _: 2
                        }, [
                            _renderList(_unref(registrationFormWidgets), (component, slotName)=>({
                                    name: slotName,
                                    fn: _withCtx((param)=>{
                                        let { props, events, ref } = param;
                                        return [
                                            (_openBlock(), _createBlock(_resolveDynamicComponent(component), _mergeProps({
                                                ref: ref
                                            }, props, _toHandlers(events)), null, 16))
                                        ];
                                    })
                                }))
                        ]), 1032, [
                            "schema",
                            "ui-schema",
                            "custom-errors",
                            "is-pending",
                            "class",
                            "onSubmit",
                            "onInput",
                            "onVnodeMounted"
                        ]),
                        _unref(isTermsCheckboxHidden) ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['registration__disclaimer'])
                        }, [
                            _createVNode(_unref(TextSlotPlaceholder), {
                                text: _ctx.$t('WEB2_SIMPLE_REGISTRATION_TERMS_ACCEPTION')
                            }, {
                                rules: _withCtx(()=>[
                                        _createVNode(_component_router_link, {
                                            to: {
                                                name: _unref(RouteName).CMS_BETTING_PARTICIPATION_AGREEMENT
                                            }
                                        }, {
                                            default: _withCtx(()=>[
                                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_SIMPLE_REGISTRATION_AGREEDM_RULES')), 1)
                                                ]),
                                            _: 1
                                        }, 8, [
                                            "to"
                                        ])
                                    ]),
                                _: 1
                            }, 8, [
                                "text"
                            ])
                        ], 2)) : _createCommentVNode("", true)
                    ], 64)) : (_openBlock(), _createBlock(_unref(VLoader), {
                        key: 1
                    })),
                    _createVNode(VReCaptchaBadge, {
                        lang: _ctx.$lang
                    }, null, 8, [
                        "lang"
                    ])
                ], 2)
            ])), [
                [
                    _directive_auto_id,
                    'RegistrationMain'
                ]
            ]);
        };
    }
});
