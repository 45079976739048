import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VForm } from 'web/src/components/Form';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useChangePasswordStage } from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/composables/useChangePasswordStage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RegistrationChangePassword',
    emits: [
        "input"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const schema = {
            $schema: 'http://json-schema.org/draft-07/schema#',
            type: 'object',
            title: 'registrationPasswordChange',
            required: [
                'oldPassword',
                'newPassword'
            ],
            properties: {
                oldPassword: {
                    type: 'string'
                },
                newPassword: {
                    type: 'string',
                    minLength: 8,
                    pattern: '^(?=.*[a-zA-Z])(?=.*[0-9]).*$'
                }
            }
        };
        const { isSuccess, goToDeposits, uiSchema, isFormPending, onChangePasswordSubmit, emitOnInput, passwordChangeForm } = useChangePasswordStage(emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['change-password-success'])
            }, [
                _unref(isSuccess) ? (_openBlock(), _createBlock(_unref(VJumbotron), {
                    key: 0,
                    icon: _unref(IconName).CHECK_OUTLINE,
                    "icon-size": _unref(IconSize).SIZE_80,
                    heading: _ctx.$t('WEB2_PASSWORD_HAS_CHANGED'),
                    class: _normalizeClass(_ctx.$style['change-password-success__jumbotron'])
                }, {
                    footer: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "default", {}, ()=>[
                                    _createVNode(_unref(JumbotronFooterButton), {
                                        label: _ctx.$t('WEB2_DEPOSIT_FUNDS'),
                                        "full-width": "",
                                        kind: _unref(ButtonKind).PRIMARY,
                                        "icon-name": _unref(IconName).ADD,
                                        onClick: _unref(goToDeposits)
                                    }, null, 8, [
                                        "label",
                                        "kind",
                                        "icon-name",
                                        "onClick"
                                    ])
                                ])
                        ]),
                    default: _withCtx(()=>[
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_REGISTRATION_SUCCESS_DESCR')) + " ", 1)
                        ]),
                    _: 3
                }, 8, [
                    "icon",
                    "icon-size",
                    "heading",
                    "class"
                ])) : (_openBlock(), _createBlock(_unref(VForm), {
                    key: 1,
                    ref_key: "passwordChangeForm",
                    ref: passwordChangeForm,
                    class: _normalizeClass(_ctx.$style['change-password-success__form']),
                    schema: schema,
                    "ui-schema": _unref(uiSchema),
                    "is-pending": _unref(isFormPending),
                    onSubmit: _unref(onChangePasswordSubmit),
                    onInput: _unref(emitOnInput)
                }, null, 8, [
                    "class",
                    "ui-schema",
                    "is-pending",
                    "onSubmit",
                    "onInput"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'RegistrationChangePassword'
                ]
            ]);
        };
    }
});
