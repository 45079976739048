import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import VSmsNumberCheck from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/VSmsNumberCheck.vue';
import { usePhoneCheckStage } from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/composables/usePhoneCheckStage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RegistrationPhoneCheck',
    emits: [
        "check-sms-code",
        "input"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const { schema, uiSchema, isFormPending, submittedStarredPhone, resendSmsTimeOut, customErrors, suggestedEmailRegistrationTimeout, isPhoneCheckByCall, emitCheckSmsCode, resendPhoneCodeByType, setRetrySmsCodeTime, changeShowHintBlock, isShowHintBlockVisible, emitInput, goToChangePhone, goToEmailRegistration, goToPhoneCheckBySmsStage } = usePhoneCheckStage(emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['registration-phone-check'])
            }, [
                _createVNode(VSmsNumberCheck, {
                    schema: _unref(schema),
                    "ui-schema": _unref(uiSchema),
                    "is-pending": _unref(isFormPending),
                    phone: _unref(submittedStarredPhone),
                    "time-left": _unref(resendSmsTimeOut),
                    "is-timer": "",
                    "custom-errors": _unref(customErrors),
                    "hint-timeout": _unref(suggestedEmailRegistrationTimeout),
                    "is-new-tel": _unref(isPhoneCheckByCall),
                    onSubmit: _unref(emitCheckSmsCode),
                    "onRetryButton-сlick": _unref(resendPhoneCodeByType),
                    onSetRetrySmsCodeTime: _unref(setRetrySmsCodeTime),
                    onShowHintWithEmailRedirect: _unref(changeShowHintBlock),
                    onInput: _unref(emitInput)
                }, {
                    phone: _withCtx(()=>[
                            _cache[1] || (_cache[1] = _createTextVNode(" (")),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['registration-phone-check__pseudo-link']),
                                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(goToChangePhone) && _unref(goToChangePhone)(...args);
                                })
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['registration-phone-check__link'])
                                }, _toDisplayString(_ctx.$t('WEB2_REGISTRATION_PHONE_CHANGE')), 3)
                            ], 2),
                            _cache[2] || (_cache[2] = _createTextVNode(") "))
                        ]),
                    _: 1
                }, 8, [
                    "schema",
                    "ui-schema",
                    "is-pending",
                    "phone",
                    "time-left",
                    "custom-errors",
                    "hint-timeout",
                    "is-new-tel",
                    "onSubmit",
                    "onRetryButton-сlick",
                    "onSetRetrySmsCodeTime",
                    "onShowHintWithEmailRedirect",
                    "onInput"
                ]),
                _unref(isShowHintBlockVisible) ? (_openBlock(), _createBlock(VHintBlock, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['registration-phone-check__hint-block'])
                }, {
                    default: _withCtx(()=>[
                            _createVNode(VCmsContent, {
                                "cms-key": _unref(isPhoneCheckByCall) ? 'WEB2_PHONE_CHECK_BY_CALL_HINT_BLOCK_AFTER_TIMEOUT' : 'WEB2_PHONE_CHECK_HINT_BLOCK_AFTER_TIMEOUT',
                                silent: "",
                                "no-padding": "",
                                class: _normalizeClass(_ctx.$style['registration-phone-check__hint-block-content']),
                                onRegistrationPhoneCheckGoToEmailRegistration: _unref(goToEmailRegistration),
                                onRegistrationPhoneCheckGoToPhoneCheckBySms: _unref(goToPhoneCheckBySmsStage)
                            }, null, 8, [
                                "cms-key",
                                "class",
                                "onRegistrationPhoneCheckGoToEmailRegistration",
                                "onRegistrationPhoneCheckGoToPhoneCheckBySms"
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "class"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'RegistrationPhoneCheck'
                ]
            ]);
        };
    }
});
