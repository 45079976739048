import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { CustomerFieldType } from '@leon-hub/api-sdk';
import { CustomCustomerFieldType } from 'web/src/modules/registration/enums';
import { VForm } from 'web/src/components/Form';
import { useChangePhoneNumberStage } from 'web/src/modules/registration/components/RegistrationChangePhoneNumber/useChangePhoneNumberStage';
import SupportLink from 'web/src/modules/phone-validation/components/PhoneValidationSupportLink/PhoneValidationSupportLink.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RegistrationChangePhoneNumber',
    emits: [
        "input",
        "change-phone"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const schema = {
            $schema: 'http://json-schema.org/draft-07/schema#',
            type: 'object',
            title: 'RegistrationChangePhone',
            required: [
                CustomerFieldType.PHONE_INPUT
            ],
            properties: {
                [CustomerFieldType.PHONE_INPUT]: {
                    properties: {
                        prefix: {
                            type: 'string',
                            pattern: '^[0-9]{0,4}$'
                        },
                        suffix: {
                            type: 'string',
                            pattern: '^[1-9][0-9]{4,14}$'
                        }
                    },
                    required: [
                        'prefix',
                        'suffix'
                    ],
                    type: 'object'
                },
                [CustomCustomerFieldType.CAPTCHA_TOKEN]: {
                    type: 'string'
                },
                [CustomCustomerFieldType.CAPTCHA_TYPE]: {
                    type: 'string'
                }
            }
        };
        const { isFormPending, submittedPhoneStarred, uiSchema, customErrors, emitInput, onChangePhoneSubmit } = useChangePhoneNumberStage(emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['registration-change-phone'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['registration-change-phone__hint'])
                }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_YOUR_CURRENT_NUMBER')) + " ", 1),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['registration-change-phone__hint--current-phone'])
                    }, _toDisplayString(_unref(submittedPhoneStarred)), 3)
                ], 2),
                _createVNode(_unref(VForm), {
                    class: _normalizeClass(_ctx.$style['registration-change-phone__form']),
                    schema: schema,
                    "ui-schema": _unref(uiSchema),
                    "custom-errors": _unref(customErrors),
                    "is-pending": _unref(isFormPending),
                    onSubmit: _unref(onChangePhoneSubmit),
                    onInput: _unref(emitInput)
                }, null, 8, [
                    "class",
                    "ui-schema",
                    "custom-errors",
                    "is-pending",
                    "onSubmit",
                    "onInput"
                ]),
                _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'RegistrationChangePhoneNumber'
                ]
            ]);
        };
    }
});
