import { computed, toRef } from 'vue';
import { RegistrationStage } from 'web/src/modules/registration/enums';
import { useSimplifiedRegistrationStore } from 'web/src/modules/registration/submodules/simplified-registration/store';
import { useRegistrationComplete } from 'web/src/modules/registration/store/composables/useRegistrationComplete';
export function useRegistrationProgress() {
    useRegistrationComplete();
    // REGISTRATION STORE AND PROGRESS FOR SLOTT
    // REGISTRATION STORE AND PROGRESS FOR LEON
    const store = useSimplifiedRegistrationStore();
    const registrationStage = toRef(store, 'stage');
    return {
        isNotFinished: computed(()=>registrationStage.value !== RegistrationStage.Success && registrationStage.value !== RegistrationStage.ChangePasswordSuccess)
    };
}
