import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withKeys as _withKeys, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';
import { ButtonKind } from '@leon-hub/module-buttons';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import CopyPassword from 'web/src/modules/registration/submodules/simplified-registration/components/CopyPassword/CopyPassword.vue';
import VTooltip from 'web/src/modules/dialogs/components/VTooltip/VTooltip.vue';
import { useSuccessStage } from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/composables/useSuccessStage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RegistrationSuccess',
    setup (__props) {
        const { userPassword, onCopyPasswordClick, goToChangePassword, goToDeposit, isTooltipShown } = useSuccessStage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['registration-success'])
            }, [
                _createVNode(_unref(VJumbotron), {
                    icon: _unref(IconName).CHECK_OUTLINE,
                    "icon-size": _unref(IconSize).SIZE_80,
                    heading: _ctx.$t('WEB2_YOUR_PASSWORD'),
                    class: _normalizeClass(_ctx.$style['registration-success__jumbotron'])
                }, {
                    footer: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "default", {}, ()=>[
                                    _createVNode(_unref(JumbotronFooterButton), {
                                        label: _ctx.$t('WEB2_DEPOSIT_FUNDS'),
                                        kind: _unref(ButtonKind).PRIMARY,
                                        "full-width": "",
                                        "icon-name": _unref(IconName).ADD,
                                        onClick: _unref(goToDeposit)
                                    }, null, 8, [
                                        "label",
                                        "kind",
                                        "icon-name",
                                        "onClick"
                                    ])
                                ])
                        ]),
                    default: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['registration-success__congrats'])
                            }, _toDisplayString(_ctx.$t('WEB2_REGISTRATION_SUCCESS_DESCR')), 3),
                            _createVNode(CopyPassword, {
                                "user-password": _unref(userPassword) || '',
                                onClick: _unref(onCopyPasswordClick),
                                onKeydown: _withKeys(_unref(onCopyPasswordClick), [
                                    "enter",
                                    "space"
                                ])
                            }, null, 8, [
                                "user-password",
                                "onClick",
                                "onKeydown"
                            ]),
                            _createVNode(VButton, {
                                tag: _unref(Tag).A,
                                label: _ctx.$t('WEB2_SETT_PASSCHANGE'),
                                kind: _unref(ButtonKind).TRANSPARENT,
                                tabindex: "0",
                                class: _normalizeClass(_ctx.$style['registration-success__change-password']),
                                onClick: _unref(goToChangePassword),
                                onKeydown: _withKeys(_unref(goToChangePassword), [
                                    "enter",
                                    "space"
                                ])
                            }, null, 8, [
                                "tag",
                                "label",
                                "kind",
                                "class",
                                "onClick",
                                "onKeydown"
                            ]),
                            _unref(isTooltipShown) ? (_openBlock(), _createBlock(VTooltip, {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['registration-success__tooltip']),
                                text: _ctx.$t('WEB2_PASSWORD_HAS_BEEN_COPIED')
                            }, null, 8, [
                                "class",
                                "text"
                            ])) : _createCommentVNode("", true)
                        ]),
                    _: 3
                }, 8, [
                    "icon",
                    "icon-size",
                    "heading",
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'RegistrationSuccess'
                ]
            ]);
        };
    }
});
