import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
    class: "terms-acception"
};
const _hoisted_2 = {
    class: "terms-acception__content"
};
import RouteName from '@leon-hub/routing-config';
import { VIcon } from '@components/v-icon';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import { VCheckbox, useCheckboxEvents } from 'web/src/components/Checkbox';
import { SpecialCheckboxesDataTest } from 'web/src/components/TermsSpecialCheckboxes/enums';
import useTermsCheckbox from '../composables/useTermsCheckbox';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RegistrationTermsAcceptionCheckbox',
    props: {
        open: {
            type: Boolean
        },
        expandable: {
            type: Boolean
        },
        name: {
            default: 'RegistrationTermsAcceptionCheckbox'
        },
        checked: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        error: {},
        tabIndex: {},
        inputDataTest: {},
        autofocus: {
            type: Boolean
        },
        noPadding: {
            type: Boolean
        }
    },
    emits: [
        "change",
        "input",
        "blur",
        "focus"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { toggleExpand, isExpanded, checkboxProps, chevronProperties } = useTermsCheckbox(props);
        const { onChange, onBlur, onFocus, onInput } = useCheckboxEvents(emit);
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _withDirectives((_openBlock(), _createBlock(_unref(VCheckbox), _mergeProps(_unref(checkboxProps), {
                        class: "terms-acception__checkbox",
                        "input-data-test": {
                            el: _unref(SpecialCheckboxesDataTest).AcceptTermsCheckbox
                        },
                        onChange: _unref(onChange),
                        onInput: _unref(onInput),
                        onFocus: _unref(onFocus),
                        onBlur: _unref(onBlur)
                    }), {
                        label: _withCtx(()=>[
                                _withDirectives((_openBlock(), _createElementBlock("span", null, [
                                    _unref(isExpanded) ? _renderSlot(_ctx.$slots, "full", {
                                        key: 1
                                    }, ()=>[
                                            _createVNode(_unref(TextSlotPlaceholder), {
                                                text: _ctx.$t('WEB2_FULL_REG_AGREEDM_INT')
                                            }, {
                                                agreement: _withCtx(()=>[
                                                        _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                                                            to: {
                                                                name: _unref(RouteName).CMS_DATA_PROCESSING_AGREEMENT
                                                            }
                                                        }, {
                                                            default: _withCtx(()=>[
                                                                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_FULL_REG_AGREEDM_AGREEMENT')), 1)
                                                                ]),
                                                            _: 1
                                                        }, 8, [
                                                            "to"
                                                        ])), [
                                                            [
                                                                _directive_data_test,
                                                                {
                                                                    el: _unref(SpecialCheckboxesDataTest).AcceptTermsLinkToAgreement
                                                                }
                                                            ]
                                                        ])
                                                    ]),
                                                rules: _withCtx(()=>[
                                                        _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                                                            to: {
                                                                name: _unref(RouteName).CMS_BETTING_PARTICIPATION_AGREEMENT
                                                            }
                                                        }, {
                                                            default: _withCtx(()=>[
                                                                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_FULL_REG_AGREEDM_RULES')), 1)
                                                                ]),
                                                            _: 1
                                                        }, 8, [
                                                            "to"
                                                        ])), [
                                                            [
                                                                _directive_data_test,
                                                                {
                                                                    el: _unref(SpecialCheckboxesDataTest).AcceptTermsLinkToOffer
                                                                }
                                                            ]
                                                        ])
                                                    ]),
                                                _: 1
                                            }, 8, [
                                                "text"
                                            ])
                                        ]) : _renderSlot(_ctx.$slots, "short", {
                                        key: 0
                                    }, ()=>[
                                            _createTextVNode(_toDisplayString(_ctx.$t('SIMPLIFIED_REG_AGREEDM_REG')), 1)
                                        ])
                                ])), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: _unref(SpecialCheckboxesDataTest).AcceptTermsText,
                                            expanded: _unref(isExpanded)
                                        }
                                    ]
                                ])
                            ]),
                        _: 3
                    }, 16, [
                        "input-data-test",
                        "onChange",
                        "onInput",
                        "onFocus",
                        "onBlur"
                    ])), [
                        [
                            _directive_data_test,
                            {
                                el: _unref(SpecialCheckboxesDataTest).AcceptTermsElement
                            }
                        ]
                    ])
                ]),
                _ctx.expandable ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "terms-acception__button",
                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(toggleExpand) && _unref(toggleExpand)(...args);
                    })
                }, [
                    _createVNode(_unref(VIcon), _mergeProps(_unref(chevronProperties), {
                        class: "terms-acception__button-chevron"
                    }), null, 16)
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: _unref(SpecialCheckboxesDataTest).AcceptTermsExpandToggle
                        }
                    ]
                ]) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'RegistrationTermsAcceptionCheckbox'
                ]
            ]);
        };
    }
});
