import { ApiErrorCode } from '@leon-hub/api';
import { AppError } from '@leon-hub/app-errors';
export class EmptyRegistrationTypeError extends AppError {
    constructor(options){
        super({
            ...options,
            code: ApiErrorCode.API_UNEXPECTED_ERROR,
            message: `Unexpected Registration Type: ${options?.message || 'currentRegistrationType is null'}`
        });
    }
}
